/* eslint-disable react/prop-types */
// import { PropTypes } from "prop-types";

import React from "react";
import { graphql } from "gatsby";
import ExhibitionCTA from "~components/ExhibitionCTA";
import Footer from "~components/Footer";
import Layout from "~components/Layout";
import SEO from "~components/SEO";

const ExhibitionPage = ({ data, location }) => {
  const { frontmatter } = data.markdownRemark;

  //

  return (
    <>
      <SEO noIndex />

      <Layout className="exhibition-page w-full relative pt-v12 bg-black text-white">
        <ExhibitionCTA />
      </Layout>

      <Footer />
    </>
  );
};

export default ExhibitionPage;

export const query = graphql`
  query ExhibitionPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        title
        seoKeywords
        seoDescription
      }
    }
  }
`;
