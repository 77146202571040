import React, { useContext } from "react";
import { graphql, StaticQuery } from "gatsby";
import Img from "gatsby-image";
import { PropTypes } from "prop-types";
import { DocumentContext } from "~context/DocumentContext";
import AppearOnScroll from "~components/AppearOnScroll";
import CursorHoverable from "~components/CursorHoverable";

const query = graphql`
  query Exhibition {
    allMarkdownRemark(
      filter: { frontmatter: { templateKey: { eq: "exhibition-page" } } }
    ) {
      edges {
        node {
          frontmatter {
            isActive
            title
            heading
            content
            date
            image {
              childImageSharp {
                fluid(maxWidth: 1440, quality: 75) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
              }
              publicURL
            }
            url
          }
        }
      }
    }
  }
`;

const ExhibitionCTA = ({ background, className, color }) => {
  const { device } = useContext(DocumentContext);

  //

  return (
    <StaticQuery
      query={query}
      render={({ allMarkdownRemark }) => {
        let frontmatter;
        let hasExhibition = false;

        if (allMarkdownRemark?.edges?.[0].node) {
          ({ frontmatter } = allMarkdownRemark.edges[0].node);

          hasExhibition = frontmatter?.isActive === true;
        }

        return (
          <>
            {(hasExhibition && (
              <section
                className={`${className} w-full relative pt-v8 xs:pt-v12 pb-v8 xs:pb-v12 bg-${background} text-${color}`}
              >
                <header className="grid">
                  <h2
                    className={`grid-end-8 sm:grid-end-24 grid-start-2 sm:grid-start-1 xs:mb-v8 ${
                      device === `desktop` ? `f3` : `f4`
                    } whitespace-pre-wrap`}
                  >
                    <AppearOnScroll once>{frontmatter.heading}</AppearOnScroll>
                  </h2>

                  <div className="grid-end-14 sm:grid-end-24 f5">
                    <AppearOnScroll once>{frontmatter.content}</AppearOnScroll>
                  </div>
                </header>

                <div className="grid">
                  <figure className="grid-end-22 sm:grid-end-24 grid-start-2 sm:grid-start-1 mt-v5 xs:mt-v16">
                    <AppearOnScroll once>
                      <Img
                        className="w-full relative block"
                        fluid={frontmatter.image.childImageSharp.fluid}
                        alt=""
                      />
                    </AppearOnScroll>
                  </figure>

                  <div className="grid-end-22 sm:grid-end-24 grid-start-2 sm:grid-start-1 relative flex justify-between mt-v1 xs:mt-v1">
                    <AppearOnScroll className="w-full relative" once>
                      <div className="w-full relative flex items-center justify-between">
                        <span
                          className={`xs:mt-v1 ${
                            device === `desktop` ? `f5` : `b1`
                          }`}
                        >
                          {frontmatter.date}
                        </span>

                        <CursorHoverable>
                          <a
                            href={frontmatter.url}
                            rel="noopener noreferrer"
                            target="_blank"
                            className={`underline ${
                              device === `desktop` ? `f5` : `b1`
                            }`}
                          >
                            View Exhibition
                          </a>
                        </CursorHoverable>
                      </div>
                    </AppearOnScroll>
                  </div>
                </div>
              </section>
            )) || <></>}
          </>
        );
      }}
    />
  );
};

/*
<section
  className={`${className} w-full relative pt-v8 pb-v8 bg-${background} text-${color}`}
>
  <div className="grid">
    <h2 className="grid-end-6 sm:grid-end-24 grid-start-2 sm:grid-start-1 f3">
      No current Exhibition
    </h2>

    <p className="grid-end-13 sm:grid-end-24 grid-start-11 sm:grid-start-1 mb-v6 xs:mt-v3 b1">
      As part of our ongoing mission to use design for good we
      initiate exhibitions with artists, brands, charities and all
      else in between, to engage with the topics that matter to
      us.
    </p>
  </div>
</section>
*/

ExhibitionCTA.defaultProps = {
  background: `black`,
  className: ``,
  color: `white`
};

ExhibitionCTA.propTypes = {
  background: PropTypes.string,
  className: PropTypes.string,
  color: PropTypes.string
};

export default ExhibitionCTA;
